<template>
  <v-expansion-panel class="payment-method">
    <v-expansion-panel-content>
      <template v-slot:actions>
        <v-switch :value="panelOpen === 0" readonly color="rgba(var(--theme-primaria))"></v-switch>
      </template>
      <template v-slot:header>
        <img width="24px" :src="`/images/payment-methods/debit.svg`" :alt="`Debit icon`" />
        <p>{{ params.title }}</p>
        <span v-if="params.installments > 1 && recurrency == 1" style="color: rgba(var(--theme-primaria))">Em até {{ params.installmentInfo.length
          }}x</span>
        <span 
            style="color: rgba(var(--theme-primaria))"
            v-else-if="recurrency > 1">
        </span>
        <span style="color: rgba(var(--theme-primaria))" v-else>
          Somente à vista
        </span>
      </template>
      <InstallmentsTemplate :installments="params.installments">
        <template #extension>
          <form class="">
            <div class="form-container ">
              <div class="form-row">
                <div class="custom-field-text disabled">
                  <input required type="text" placeholder="Nome do titular da conta" />
                </div>
              </div>

              <div class="form-row">
                <div class="custom-field-select disabled">
                  <select required name="bank">
                    <option value="">Banco</option>
                    <option value="banco1">Banco 1</option>
                    <option value="banco2">Banco 2</option>
                  </select>
                </div>
                <div class="custom-field-select disabled">
                  <select required name="debit-day">
                    <option value="">Dia para débito</option>
                    <option value="banco1">Banco 1</option>
                    <option value="banco2">Banco 2</option>
                  </select>
                </div>
              </div>

              <div class="form-row">
                <div style="width: 50%;" class="custom-field-text disabled">
                  <input required type="text" placeholder="Agência" />
                </div>
                <div style="width: 35%;" class="custom-field-text disabled">
                  <input required type="text" placeholder="Conta" />
                </div>
                <div style="width: 15%;" class="custom-field-text disabled">
                  <input required type="text" placeholder="Dígito" />
                </div>
              </div>
            </div>
            <v-btn class="btn btn-secondary" @click="redirect"
              style="background-color: rgba(var(--theme-primaria)) !important">
              Pagar com débito em folha
              <i class="fal fa-angle-right"></i>
            </v-btn>
          </form>
        </template>
      </InstallmentsTemplate>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import InstallmentsTemplate from "./InstallmentsTemplate.vue";

export default {
  emits: ["redirect"],
  components: { InstallmentsTemplate },
  props: ["params", "openPanel"],
  computed: {
    paymentType: {
      get() {
        return this.$store.getters.paymentType;
      },
      set(value) {
        this.$store.dispatch('setPaymentType', value)
      }
    }
  },
  watch: {

    panelOpen(value) {
      if (value !== null) {
        this.$emit('update:activePaymentMethod','AccountDebit')
        this.$emit("set-open-panel", 'AccountDebit')
        this.paymentType = 'AccountDebit'
        this.panelOpen = 0
      }
    },
    openPanel(newVal) {
      if (newVal !== "AccountDebit") {
        this.panelOpen = null;
      }
    },
    paymentType(value) {
      if (value !== 'AccountDebit') this.panelOpen = null
    },
  },
  data() {
    return {
      panelOpen: null,
    };
  },
  methods: {
    redirect() {
      this.$emit("redirect");
    },
  },
};
</script>
