<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" scrollable max-width="900px">
      <v-card>
        <v-card-title class="headline">
          <h1>{{ title }}</h1>
          <v-btn class="close-modal" @click="dialog = false">
            <i class="fal fa-times"></i>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 550px" class="bg-theme-fundo">
          <div class="list-scroll donation-list">
            <list-campaign-donation-products ref="listCampaignDonationProducts" :showDonationButton="true"
              :isModal="true" :donationProductList="donationProductList"
              :projectToAdd="project" @close="close()" />
          </div>
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script type="plain/text">
import ListCampaignDonationProducts from "./ListCampaignDonationProducts.vue";
import DonationProduct from "@/scripts/models/donationProduct.model";

export default {
  components: {
    ListCampaignDonationProducts,
  },
  props: {
    donationProductList: [DonationProduct],
    title: String
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$emit('close')
    },
  },
};
</script>
