<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" scrollable max-width="680px">
      <v-card>
        <v-card-title class="headline">
          <h1>{{getProjectTypeName(projectType)}}</h1>
          <a href="#"></a>
          <v-btn class="close-modal" @click="dialog = false">
            <i class="fal fa-times"></i>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="max-height: 550px;">
          <v-layout w100 d-flex pa-4 list-scroll>
            <v-flex xs12 class="pt-2 border-horizontal display-flex f-d-column">
              <div v-if="projectType == projectTypes.ProjetoIncentivado">
                <h3 class="mb-3 f-size-20">O que são projetos com incentivo e quem pode doar?</h3>
                <p
                  class="f-size-16"
                >Projetos incentivados são projetos pré-aprovados por órgãos do governo (ministérios e conselhos de direito), os quais permitem doações com abatimento pelo imposto de renda.</p>
                <p
                  class="f-size-16 fw-600"
                >A pessoa física pode destinar até {{ incomeTax }} do imposto de renda devido, na versão completa, para projetos incentivados voltados a Crianças e adolescentes, Idosos, Esporte e Cultura.</p>
                <div>
                  <!-- importante inserir vídeo -->
                  <!--<iframe width="340" height="220" src="https://www.youtube-nocookie.com/embed/-H0wDa5kswk" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>-->
                  <div class="text-xs-center pt-3 pb-4">
                    <v-btn
                      large
                      round
                      flat
                      @click="dialog = false; $emit('simulation')"
                      class="btn-primary ma-0"
                      color="white"
                    >
                      <Icon name="fas fa-hand-holding-usd mr-2" size="18" colorFont="white" />Descubra seu potencial de doação
                    </v-btn>
                  </div>
                </div>
              </div>
              <div v-if="projectType == projectTypes.ProjetoSemIncentivoFiscal">
                <p>Sem incentivo</p>
              </div>
              <div v-if="projectType == projectTypes.Ticket">
                <p>Ticket</p>
              </div>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script type="plain/text">
import {
  ProjectTypes,
  GetProjectTypeName
} from "@/scripts/models/enums/projectTypes.enum.js";

// Vuex
import { mapGetters, mapActions } from "vuex";

export default {
  components: {},
  data() {
    return {
      dialog: false,
      projectTypes: ProjectTypes,
      getProjectTypeName: GetProjectTypeName,
      projectType: ProjectTypes
    };
  },
  computed: {
    ...mapGetters([
      'incomeTax'
    ]),
  },
  methods: {
    ...mapActions([
      'setIncomeTax'
    ]),
    open(projectType) {
      this.dialog = true;
      this.projectType = projectType;
      this.$store.dispatch('setIncomeTax');
    }
  }
};
</script>
