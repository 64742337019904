<template>
  <v-expansion-panel class="payment-method" v-model="panelOpen">
    <v-expansion-panel-content>
      <template v-slot:actions>
        <v-switch :value="panelOpen === 0" readonly color="rgba(var(--theme-primaria))"></v-switch>
      </template>
      <template v-slot:header>
        <img width="24px" :src="`/images/payment-methods/pix.svg`" :alt="`Pix icon`" />
        <p>{{ params.title }}</p>
        <span v-if="params.installments > 1 && recurrency == 1" style="color: rgba(var(--theme-primaria))">Em até {{ params.installmentInfo.length
          }}x</span>
        <span 
          style="color: rgba(var(--theme-primaria))"
          v-else-if="recurrency > 1">
        </span>
        <span style="color: rgba(var(--theme-primaria))" v-else>
          Somente à vista
        </span>
      </template>
      <InstallmentsTemplate :installments="params.installments">
        <template #extension>
          <div class="mb-4">
            <v-btn class="btn btn-secondary" @click="save"
              style="background-color: rgba(var(--theme-primaria)) !important">
              Pagar com Pix
              <i class="fal fa-external-link"></i>
            </v-btn>
          </div>
        </template>
      </InstallmentsTemplate>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import InstallmentsTemplate from "./InstallmentsTemplate.vue";

// VUEX
import { mapGetters, mapActions } from "vuex";

// Services
import CheckoutService from "@/scripts/services/checkout.service.js"
import cartStorageService from "@/scripts/services/cartStorage.service";

// Enums
import PaymentMethod from "@/scripts/models/enums/paymentMethod.enum";

export default {
  emits: ["redirect"],
  components: { InstallmentsTemplate },
  props: ["params", "openPanel"],
  computed: {
    ...mapGetters([
      "cartItems",
      "checkoutValue",
      "campaignLandingPageConfig",
      "cartLocal",
      "profile",
      "consentTermIsChecked",
      "selectedGift",
      "itemsToCheckout",
      "selectedInstallment",
      "paymentDay",
      "paymentInstallments",
      "recurrency",
      "campaignDonationProductAnswers"
    ]),
    paymentType: {
      get() {
        return this.$store.getters.paymentType;
      },
      set(value) {
        this.$store.dispatch('setPaymentType', value)
      }
    },
    taxOwner() {
      return this.campaignLandingPageConfig.campaign.taxOwnerPix;
    }
  },
  watch: {
    panelOpen(value) {
      if (value !== null) {
        this.$emit('update:activePaymentMethod','Pix')
        this.$emit("set-open-panel", 'Pix')
        this.paymentType = 'Pix'
        this.cartLocal.paymentMethodId = this.PaymentMethod[this.paymentType]
        this.panelOpen = 0
        this.setInstallment(this.paymentInstallments.pix.installmentInfo[0]) // Set first installment default
      }
    },
    openPanel(newVal) {
      if (newVal !== "Pix") {
        this.panelOpen = null;
      }
    },
    paymentType(value) {
      if (value !== 'Pix') this.panelOpen = null
    },
    recurrency(val) {
      if (val > 1 && this.paymentType === 'Pix')
        this.setInstallment(this.paymentInstallments.pix.installmentInfo[0])
    },
  },
  data() {
    return {
      checkoutService: new CheckoutService(),
      PaymentMethod: PaymentMethod,
      panelOpen: null,
    };
  },
  methods: {
    ...mapActions([
      "createInstallments",
      "setInstallment",
      "setPayrollInstallment",
      "clearCart",
      "setCartItems",
      "setCartSuccess"
    ]),
    redirect(data) {
      this.$emit("redirect", data);
    },
    getParcelamentNumber() {
      const parcelamentList = this.campaignLandingPageConfig.campaign.campaignPaymentMethodParcelamentList
      const paymentParcelament = parcelamentList.filter(item => item.paymentMethodId === this.PaymentMethod[this.paymentType])
      return paymentParcelament[0].parcelament;
    },
    save() {
      if (this.consentTermIsChecked) this.createOrder()
      else {
        this.$store.commit(
          "SET_SNACKBAR_MESSAGE",
          "Para prosseguir é necessário confirmar o termo de anuência!"
        );
      }
    },
    createOrder() {
      const cart = cartStorageService.getCart()
      
      let order = {
        userId: this.profile.userId,
        cartId: (cart && cart.id) ? cart.id : 0,
        campaignId: this.campaignLandingPageConfig.campaignId,
        campaignProductId: this.cartLocal.campaignProductId,
        paymentMethodId: this.PaymentMethod.Pix,
        paymentRecurrencePeriodId: this.recurrency,
        parcelamentPayRollAccount: 0,
        paymentDay: this.paymentDay,
        campaignDonationProductAnswers: this.campaignDonationProductAnswers,
        installments: this.selectedInstallment.installment,
        pix: {
          expires_in: 86400
        },
        totalValue: this.selectedInstallment.total_value,
        donationProductId: 0,
        coin: "BRL",
        cartItems: this.createItemsObject(),
        expectedCarts: [],
        giftId: this.selectedGift ? this.selectedGift.id : null,
        giftValue: this.selectedGift ? this.selectedGift.initialValue : null
      }

      if (this.taxOwner === 2 && this.selectedInstallment.total_value != this.checkoutValue) {
        order.taxValue = (this.selectedInstallment.total_value - this.checkoutValue)
      }
      
      if (cart && cart.id) {
        order.creationDate = cart.creationDate
      }

      this.checkoutService.postCheckout(order).then((response) => {
        if (response) {
          this.setCartSuccess({
            cartId: response.cart_id,
            totalValue: order.totalValue,
            campaign: this.campaignLandingPageConfig.campaign,
            paymentMethodId: order.paymentMethodId,
          });

          this.clearCart()
          this.redirect({
            method: 'pix',
            data: response
          })
        } else {
          this.$store.commit(
            "SET_SNACKBAR_MESSAGE",
            'A transação foi enviada, porém a operadora retornou um erro.'
          );
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    createItemsObject() {
      const items = [];

      for (let item of this.itemsToCheckout) {
        items.push({
          id: item.id,
          projectId: item.projectId,
          project: { id: item.project.id, projectTypeId: item.project.projectTypeId },
          itemValue: parseFloat(item.itemValue),
          donationProduct: item.donationProduct,
          donationProductId: item.donationProductId
        })
      }
      return items
    },
  },
};
</script>
