<template>
  <Modal :value="true" v-if="dialog" modalTitle="Termo de Anuência" :cardTitle="true" :withClose="true"
    :modalFooter="true" myMaxWidth="690px" @input="setConsentTermDialog">

    <v-container fluid pa-0>
      <v-layout pa-0>
        <v-flex list-scroll sm12 style="height: 100%">
          <div class="content-text-block overflow font-sys">
            <div class="pa-4 content-terms">
              <p class="mb-0">
                Eu,
                <strong>{{ person ? person.name : "" }}</strong>
              </p>
              <p class="mb-0" v-if="person && (person.cpf == null || person.cpf == '')">
                AUTORIZO
                <template v-if="isPayroll()">, de forma voluntária e irretratável</template>
                , que a doação especificada abaixo seja realizada em nome:
              </p>
              <p class="mb-0" v-else>
                CPF n°
                <span class="notranslate font-bold">{{ person ? formatterHelper.formatCpf(person.cpf) : "" }}</span>
                AUTORIZO
                <template v-if="isPayroll()">, de forma voluntária e irretratável</template>, que a doação especificada
                abaixo seja
                realizada em nome:
              </p>
              <BorderSeparation class="no-margin-2" />
              <div class="terms-itens">
                <p class="font-bold">Valor da doação:</p>
                <p class="ml-4 notranslate">
                  R$ {{ formatterHelper.formatMoney(totalPrice) }}
                  {{
                    showValueText
                      ? "(" +
                      formatterHelper.writeNumbersAsText(
                        formatterHelper.formatMoney(totalPrice)
                      ) +
                      ")"
                      : ""
                  }}
                </p>
              </div>
              <div v-if="taxValue > 0" class="terms-itens">
                <p class="font-bold">Taxa do Meio de Pagamento<label v-if="cart && selectedInstallment.installment > 1">,<br /> por
                    parcela</label>:
                </p>
                <p class="notranslate ml-4 text-sm">
                  R$ {{ formatterHelper.formatMoney(getTotalTaxByParcelament()) }}
                  {{
                    showValueText
                      ? "(" +
                      formatterHelper.writeNumbersAsText(
                        formatterHelper.formatMoney(getTotalTaxByParcelament())
                      ) +
                      ")"
                      : ""
                  }}
                </p>
              </div>
              <div v-if="taxValue > 0" class="terms-itens">
                <p class="font-bold">Taxa Total do Meio de Pagamento:</p>
                <p class="notranslate ml-4 text-sm">
                  R$ {{ formatterHelper.formatMoney(getTotalTax()) }}
                  {{
                    showValueText
                      ? "(" +
                      formatterHelper.writeNumbersAsText(
                        formatterHelper.formatMoney(getTotalTax())
                      ) +
                      ")"
                      : ""
                  }}
                </p>
              </div>
              <div class="terms-itens" v-if="cart &&
                selectedInstallment.installment &&
                selectedInstallment.installment > 1">
                <p class="font-bold">Parcelamento:</p>
                <p class="ml-4 text-sm"> {{ selectedInstallment.installment }}x de R$ {{
                  formatterHelper.formatMoney(selectedInstallment.value)
                }}
                  {{
                    showValueText
                      ? "(" +
                      formatterHelper.writeNumbersAsText(
                        formatterHelper.formatMoney(selectedInstallment.value)
                      ) +
                      ")"
                      : ""
                  }}
                </p>
              </div>
              <div class="terms-itens" v-if="cart &&
                selectedInstallment.howMany &&
                selectedInstallment.howMany >= 0">
                <p class="font-bold">Parcelamento:</p>
                <p class="ml-4 text-sm"> {{ selectedInstallment.howMany }}x - {{ selectedInstallment.description }}
                </p>
              </div>
              <div v-if="taxValue > 0" class="terms-itens total">
                <p class="font-bold">Total a ser pago <br />(doação + taxa):</p>
                <p class="notranslate ml-4 text-sm">
                  R$ {{ formatterHelper.formatMoney(selectedInstallment.total_value) }}
                  {{
                    showValueText
                      ? "(" +
                      formatterHelper.writeNumbersAsText(
                        formatterHelper.formatMoney(selectedInstallment.total_value)
                      ) +
                      ")"
                      : ""
                  }}
                </p>
              </div>
              <div class="terms-itens">
                <p class="font-bold">Forma de Pagamento:</p>
                <p v-if="selectedInstallment.installment > 1">Parcelado</p>
                <p v-else>À Vista</p>
              </div>
              <div class="terms-itens">
                <p class="font-bold">Tipo de Recorrência:</p>
                <p>{{ getRecurrenceName }}</p>
              </div>
              <div class="terms-itens">
                <p class="font-bold">Meio de pagamento:</p>
                <p>{{ showPaymentMethodText }}</p>
              </div>
              <div class="terms-itens">
                <p class="font-bold">Campanha:</p>
                <p>{{ getCampaignName() }}</p>
              </div>
              <div class="terms-itens">
                <p class="font-bold">Responsável pela campanha:</p>
                <p>{{ entityName }}</p>
              </div>
              <div class="terms-itens">
                <p class="font-bold">CNPJ:</p>
                <p>{{ formatterHelper.formatCnpj(entityCNPJ) }}</p>
              </div>
              <div class="terms-itens mt-5">
                <p class="font-bold">Projeto:</p>
                <p>Valor da doação</p>
              </div>
              <template v-for="item in getCartItems()">
                <div class="terms-itens" :key="item.id" v-if="!item.donationProduct">
                  <p>
                    <strong>{{ item.project.shortName }}</strong>
                  </p>
                  <p class="notranslate">
                    <strong class="notranslate">
                      {{ formatterHelper.formatMoneyWithCents(item.itemValue) }}
                    </strong>
                  </p>
                </div>
                <div class="terms-itens" :key="item.id" v-else>
                  <p>
                    <strong>{{ item.donationProduct.name }}</strong>
                  </p>
                  <p class="notranslate">
                    <strong>
                      {{ item.donationProduct.currentQuantity }}x R$
                      {{
                        formatterHelper.formatMoney(
                          item.donationProduct.fixedValue
                        )
                      }}
                    </strong>
                  </p>
                </div>
              </template>
            </div>
            <v-card-actions class="pa-0 bg-white mt-4" style="height: auto; display: flex">
              <template v-if="showTextArea" class="mt-3">
                <div class="bg-white tarea pa-2" v-html="generalConsentTerm"></div>
              </template>
              <template v-else>
                <div v-html="generalConsentTerm"></div>
              </template>
            </v-card-actions>
          </div>
        </v-flex>
      </v-layout>
    </v-container>

    <template v-slot:footer>
      <v-container fluid>
        <v-layout>
          <v-flex text-xs-center>
            <v-btn flat round color="white" class="btn-primary px-5 text-uppercase" large
              @click="acceptConsentTerm">Concordo</v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </template>
  </Modal>

</template>
<script type="plain/text">
// VUEX
import { mapGetters, mapActions } from "vuex";

import Modal from "@/components/Modal.vue";
import PaymentMethod from "@/scripts/models/enums/paymentMethod.enum";
import PersonService from "@/scripts/services/person.service.js";
import CampaignService from "@/scripts/services/campaign.service.js";
import FormatterHelper from "@/scripts/helpers/formatter.helper";
import Person from "@/scripts/models/person.model.js";
import { ProjectTypes } from "@/scripts/models/enums/projectTypes.enum";
import CartStorage from "@/scripts/services/cartStorage.service";

export default {
  props: {
    parcelament: String,
    personProp: {
      type: Object,
      default: null,
    },
  },
  components: {
    Modal,
  },
  data() {
    return {
      formatterHelper: new FormatterHelper(),
      PaymentMethod: PaymentMethod,
      personService: new PersonService(),
      campaignService: new CampaignService(),
      ProjectTypes: ProjectTypes,
      creditCardText: `DECLARO que minha doação é voluntária.
                      Declaro ciência que para as transações de CARTÃO DE CRÉDITO, considerando principalmente sua finalidade social, não estará passível para pedido de “CHARGEBACK” junto a operadora / emissora do cartão de crédito utilizado. Declaro ainda ser responsável pelo cartão de crédito utilizado e estar ciente de que essa operação, pela sua natureza não está passível de estorno ou devolução após o seu processamento, salvo em ocorrências fraudulentas, devidamente comprovadas.`,
      payrollText: `DECLARO estar ciente que no pagamento via "Desconto em folha" com ou sem parcelamento:
                    - O referido valor não ultrapassa o limite de 30% do total de deduções do meu holerite;
                    - No caso de doações recorrentes, o cancelamento pode ser realizado a qualquer momento;
                    - No caso de desligamento ou pedido de demissão antes da efetivação dos descontos, o valor da doação será descontado integralmente na rescisão do contrato de trabalho;

                    DECLARO ainda estar ciente, que para as DOAÇÕES VIA INCENTIVO FISCAL, que as expectativas de dedução/restituição de Imposto de Renda decorrentes das doações ora efetivadas são de minha exclusiva responsabilidade.
                    - Das regras da legislação que regem essa modalidade de doação (destinação do IR – específico para optantes da declaração do Imposto de Renda no modelo completo) e as compensações que posso realizar na minha declaração de ajuste anual do Imposto de Renda (pessoa física);
                    - Que devo guardar o comprovante de pagamento/doação por no mínimo 5 anos para apresentação em eventual fiscalização no Imposto de Renda pessoa física`,
      accountDebitText: `DECLARO que minha doação é voluntária. Autorizo o lançamento especificado acima em débito automático autorizado no domicílio bancário informado. Estou ciente que ao optar por débito automático autorizado é necessário entrar em contato com um dos canais eletrônicos meu banco e liberar o débito da sua doação.
                          Obs - Acesse o internet banking do seu banco, busque por "Débito Automático", procure o lançamento de sua doação e faça a autorização. Esse processo é realizado apenas uma vez.`,
      defaultText: "DECLARO que minha doação é voluntária.",
      ticketText: "",
      pixText: "",
      showTextArea: true,
      showValueText: true,
    };
  },
  created() {
    this.setLandingPageConfig();
    this.getCampaignConsentTerms()
  },
  mounted() {
    this.refreshCart()
  },
  watch: {
    dialog(val) {
      if (val)
        this.generateConsentTerm()
    }
  },
  computed: {
    ...mapGetters({
      campaignLandingPageConfig: "campaignLandingPageConfig",
      recurrencePeriodList: "recurrencePeriodList",
      totalPrice: "checkoutValue",
      entityCNPJ: "entityCNPJ",
      entityName: "entityName",
      cart: "cartLocal",
      dialog: "checkoutConsentTerm",
      coin: "coinObject",
      person: "profile",
      recurrency: "recurrency",
      itemsToCheckout: 'itemsToCheckout',
      checkoutRequest: 'checkoutRequest',
      selectedInstallment: 'selectedInstallment',
      defaultConsentTermsText: 'defaultConsentTermsText',
      consentTermsText: 'consentTermsText',
      generalConsentTerm: 'generalConsentTerm',
      paymentType: 'paymentType',
      paymentTaxes: 'paymentTaxes',
    }),
    showCardNumber() {
      const cardNumber = this.checkoutRequest.creditCardInfo.cardNumber
      const result = cardNumber.substr(cardNumber.length - 4);
      return "**** **** **** " + result;
    },
    getRecurrenceName() {
      switch (this.recurrency) {
        case 1:
          return "Única";
        case 2:
          return "Todo mês";
        case 3:
          return "Bimestral";
        case 4:
          return "Trimestral";
        case 5:
          return "Quadrimestral";
        case 6:
          return "Semestral";
        case 7:
          return "Anual";
        default:
          return "Única";
      }
    },
    showPaymentMethodText() {
      if (this.cart) {
        if (
          this.paymentType == 'CreditCard'
        ) {
          return "Cartão de Crédito - " + this.showCardNumber;
        } else if (this.paymentType == 'Ticket') {
          return "Boleto";
        } else if (this.paymentType == 'AccountDebit') {
          return "Débito em conta";
        } else if (this.paymentType == 'Pix') {
          return "Pix";
        } else if (
          this.paymentType == 'PayrollDeduction'
        ) {
          if (this.parcelament) {
            return this.parcelament.description;
          }
          return "Desconto em folha"
        }
      }
      return "";
    },
    taxValue() {
      if (this.paymentType && this.paymentTaxes && this.paymentType !== 'PayrollDeduction')
        return this.selectedInstallment.total_value - this.totalPrice;
      else
        return 0
    },
    taxOwner() {
      if (this.paymentType && this.paymentTaxes && this.paymentType !== 'PayrollDeduction')
        return this.paymentTaxes[this.paymentType].taxOwner
      else
        return 1
    },
  },
  methods: {
    ...mapActions([
      'setLandingPageConfig',
      'refreshCart',
      'setConsentTermDialog',
      'setConsentTermIsChecked',
      'setConsentTerm',
      'getCampaignConsentTerms',
    ]),
    getTotalTaxByParcelament() {
      let totalValue = this.taxValue;
      if (this.isCreditCard() || this.isPix()) {
        totalValue = this.taxValue / (this.selectedInstallment.installment > 1 ? this.selectedInstallment.installment : 1);
      }
      else if (this.isTicket()) {
        totalValue = this.taxValue / this.selectedInstallment.installment;
      }
      return totalValue;
    },
    getTotalTax() {
      return this.taxValue * ((this.selectedInstallment > 1 && !this.isCreditCard()) ? this.selectedInstallment : 1);
    },
    generateConsentTerm() {
      var text = "";
      const consentTermIndex = this.consentTermsText.findIndex((item) => item.paymentMethodId === this.cart.paymentMethodId)
      const defaultConsentTermIndex = this.defaultConsentTermsText.findIndex((item) => item.paymentMethodId === this.cart.paymentMethodId)

      if (consentTermIndex !== -1 && this.consentTermsText[consentTermIndex].paymentMethodConsentTermText !== '') text = this.consentTermsText[consentTermIndex].paymentMethodConsentTermText
      else if (defaultConsentTermIndex !== -1) text = this.defaultConsentTermsText[defaultConsentTermIndex].defaultText
      else text = this.defaultConsentTermsText[0].defaultText

      this.setConsentTerm(text)
    },
    open() {
      var translate = google.translate.TranslateElement().c;
      if (translate == "en") {
        this.showValueText = false;
      } else {
        this.showValueText = true;
      }
      if (this.personProp != null) this.person = this.personProp;
      else {
        if (this.person.id == 0)
          this.personService.getProfile(this.getProfileCallback);
        else this.dialog = true;
      }
    },
    acceptConsentTerm() {
      this.setConsentTermIsChecked(true)
      this.setConsentTermDialog(false)
    },
    getCampaignName() {
      if (
        this.campaignLandingPageConfig &&
        this.campaignLandingPageConfig.campaign
      )
        return this.campaignLandingPageConfig.campaign.name;
      return "";
    },
    getCartItems() {
      if (this.itemsToCheckout != null && this.itemsToCheckout.length > 0)
        return this.itemsToCheckout;

      return [];
    },
    isCreditCard() {
      if (this.cart && this.paymentType == 'CreditCard') {
        return true;
      }
      return false;
    },
    isAccountDebit() {
      if (
        this.cart &&
        this.cart.paymentMethodId == PaymentMethod.AccountDebit
      ) {
        return true;
      }
      return false;
    },
    isPayroll() {
      if (
        this.cart &&
        this.cart.paymentMethodId == PaymentMethod.PayrollDeduction
      ) {
        return true;
      }
      return false;
    },
    isPix() {
      if (
        this.cart &&
        this.cart.paymentMethodId == PaymentMethod.Pix
      ) {
        return true;
      }
      return false;
    },
    isTicket() {
      if (this.cart && this.cart.paymentMethodId == PaymentMethod.Ticket) {
        return true;
      }
      return false;
    },
    hasTickets() {
      var items = this.getCartItems();
      for (let i = 0; i < items.length; i++) {
        if (items[i].project.projectTypeId == ProjectTypes.Ticket) return true;
      }
      return false;
    },


  },
};
</script>


<style>
.text-sm {
  font-size: 12px;
}


.ml-4 {
  margin-left: 1rem;
}

.font-bold {
  font-weight: bold;
}
</style>
