<template>
  <v-expansion-panel class="payment-method" v-model="panelOpen">
    <v-expansion-panel-content>
      <template v-slot:actions>
        <v-switch :value="panelOpen === 0" readonly
        color="rgba(var(--theme-primaria))"
        ></v-switch>
      </template>
      <template v-slot:header>
        <img
          width="24px"
          :src="`/images/payment-methods/credit-card.svg`"
          :alt="`Credit card icon`"
        />
        <p>{{ params.title }}</p>
        <span v-if="params.installments > 1 && recurrency == 1"
          style="color: rgba(var(--theme-primaria))"
          >Em até {{ params.installmentInfo.length }}x</span
        >
        <span 
          style="color: rgba(var(--theme-primaria))"
          v-else-if="recurrency > 1">
        </span>
        <span 
          style="color: rgba(var(--theme-primaria))"
        v-else>
          Somente à vista
        </span>
      </template>
      
      
      <InstallmentsTemplate 
        :installments="params.installments" 
        paymentMethod="creditCard"
        @showCardForm="showCardForm = $event"
        >
        <template #cardForm>
          <v-card v-if="cardId === 'new'" style="padding: 0px">
        <div v-if="cards.length > 0" @click="exitCard" class="exit-card"
          >
            <i style="color: rgba(var(--theme-primaria))" 
            class="fal fa-arrow-left"></i>
            <a style="color: rgba(var(--theme-primaria))"
            >Escolher um cartão salvo</a>
          </div>
        
        <v-layout row wrap v-if="cardId === 'new'" class="card-container" style="margin: 0px">
          <label style="font-weight: 500;margin-bottom: 10px;">Dados do títular do cartão</label>
          <v-flex xs12 md12>
            <InputText
                ref="holderNameInput"
                required="true"
                type="text"
                placeHolder="Nome, como está escrito no cartão"
                autocomplete="cc-name"
                :labelCharLimit="280"
                invalidInputMessage="Preencha o nome que consta no cartão"
                :validation="validateCardName"
                v-model="card.holder_name"
                @input="nameToUpperCase()"
            />
            </v-flex>
            <v-flex xs12 md6 pr-3>
              <InputText
                  ref="cardNumberInput"
                  required="true"
                  type="tel"
                  placeHolder="Número do cartão"
                  autocomplete="cc-number"
                  mask="#### #### #### ####"
                  invalidInputMessage="Preencha o número do cartão"
                  :validation="creditCardNumberValidation"
                  v-model="card.number"
              />
            </v-flex>
            <v-flex xs12 md6>
                <InputText
                    ref="cvvInput"
                    type="tel"
                    required="true"
                    placeHolder="Código de Segurança-CVV"
                    autocomplete="cc-csc"
                    v-model="card.cvv"
                    mask="####"
                    invalidInputMessage="Preencha o código de segurança"
                    :validation="cvvValidation"
                />
            </v-flex>
            <v-flex xs12 md6 pr-3>                                                    
              <InputSelect 
                  ref="expirationMonthInput"
                  required="true"
                  :items="monthsList"
                  v-model="card.exp_month"
                  placeholderText="Mês de vencimento"
                  invalidInputMessage="Preencha o mês de vencimento"
              />                                                    
            </v-flex>
            <v-flex xs12 md6>
              <InputSelect 
                ref="expirationYearInput"
                required="true"
                :items="yearsList"
                v-model="card.exp_year"
                placeholderText="Ano de vencimento"
                invalidInputMessage="Preencha o ano de vencimento"
              />
            </v-flex>
            <v-checkbox
              label="Salvar este cartão para usar depois"
              color="rgba(var(--theme-primaria))"
              style="display: flex; align-items: center; margin-bottom: 0px;"
              v-model="saveCard"
            ></v-checkbox>
            <v-divider></v-divider>
          </v-layout>
        </v-card>
        </template>

        <template #cardContent>
          <div class="custom-field-select" 
              v-if="cardId !== 'new'">
              <label class="card-label">Selecione o cartão:</label>
              <div class="custom-checkbox"
              style="display: flex; flex-direction: column;"
              >
                <v-radio-group v-model="cardId" style="width: 100%">
                  <div v-for="card in cards" 
                  :key="card.id" class="radio-container" 
                  @click="selectCard(card.id)">
                    <v-radio
                      :label="`${card.brand}    **** ${card.last_four_digits}`"
                      :value="card.id"
                      color="rgba(var(--theme-primaria))"
                      style="margin: 0"
                      readonly
                    ></v-radio>
                  </div>
                  <div class="radio-container new-card-item"
                  @click="selectCard('new')">
                  <p>Usar um novo cartão</p>
                    <i class="fas fa-chevron-right"></i>
                  </div>
                </v-radio-group>
              </div>
            </div>
            <div v-if="cardId !== 'new' && cardId !== null">
              <v-flex xs12 md6>
                <InputText
                    ref="cvvInput"
                    type="tel"
                    required="true"
                    placeHolder="Código de Segurança-CVV"
                    autocomplete="cc-csc"
                    v-model="cardChoosenCvv"
                    mask="####"
                    invalidInputMessage="Preencha o código de segurança"
                    :validation="cvvValidation"
                    @blur="createCreditCardInfoForCardChoosen"
                    id="cardChoosenCvvInput"
                />
              </v-flex>
            </div>
        </template>
        <template #extension>
          <div style="padding-bottom: 25px">
            <v-btn class="btn btn-secondary" @click="save"
            style="background-color: rgba(var(--theme-primaria)) !important;"
            >
              Pagar com cartão de crédito
              <i class="fal fa-angle-right"></i>
            </v-btn>
          </div>
        </template>
      </InstallmentsTemplate>

    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import InstallmentsTemplate from "./InstallmentsTemplate.vue";

// VUEX
import { mapGetters, mapActions } from 'vuex'

// Components
import ValidatableComponent from "@/components/base/ValidatableComponent.vue";

// Services
import PagarMeService from '@/scripts/services/pagarMe.service.js'
import CartService from '@/scripts/services/cart.service.js'
import CheckoutService from '@/scripts/services/checkout.service.js'
import cartStorageService from "@/scripts/services/cartStorage.service";

// Enums
import PaymentMethod from "@/scripts/models/enums/paymentMethod.enum";
import PaymentMethodProvider from "@/scripts/models/enums/paymentMethodProvider.enum";

export default {
  emits: ["redirect"],
  components: { InstallmentsTemplate },
  props: ["params", "openPanel"],
  extends: ValidatableComponent,
  created() {
    this.mountYearList();
    this.mountMonthList();
  },
  mounted() {
    this.clearCheckoutRequest();
  },
  computed: {
    ...mapGetters([
      "profile",
      "cartLocal",
      "cartItems",
      "checkoutValue",
      "installments",
      "campaignLandingPageConfig",
      "consentTermIsChecked",
      "selectedGift",
      "itemsToCheckout",
      "selectedInstallment",
      "paymentMethodProviders",
      "checkoutRequest",
      "recurrency",
      "paymentInstallments",
      "campaignDonationProductAnswers"
    ]),
    months() {
      return this.monthsList;
    },
    years() {
      return this.yearsList;
    },
    paymentType: {
      get () {
        return this.$store.getters.paymentType;
      },
      set (value) {
        this.$store.dispatch('setPaymentType', value)
      }
    },
    checkoutRequestCondition() {
      return (this.card.number && this.card.cvv && this.card.exp_month && this.card.exp_year && this.card.holder_name)
    },
    taxOwner() {
      return this.campaignLandingPageConfig.campaign.taxOwnerCreditCard;
    }
  },
  watch: {
    async panelOpen(value) {
      if (value !== null) {
        this.$emit('update:activePaymentMethod', 'CreditCard');
        this.$emit("set-open-panel", "CreditCard");
        this.paymentType = 'CreditCard'
        this.panelOpen = 0
        this.cartLocal.paymentMethodId = this.PaymentMethod[this.paymentType]
        await this.getCustomerCards()
        this.setInstallment(this.paymentInstallments.creditcard.installmentInfo[0]) // Set first installment default
      }
    },
    openPanel(newVal) {
      if (newVal !== "CreditCard") {
        this.panelOpen = null;
      }
    },
    paymentType(value) {
      if (value !== 'CreditCard') this.panelOpen = null
    },
    recurrency(val) {
      if(val > 1 && this.paymentType === 'CreditCard')
      this.setInstallment(this.paymentInstallments.creditcard.installmentInfo[0])
    },
    card: {
      handler(newVal) {
        if (this.checkoutRequestCondition) {
          this.setCheckoutRequest({
            creditCardInfo: {
              cardNumber: this.card.number,
              cvv: this.card.cvv,
              expirationMonth: this.card.exp_month,
              expirationYear: this.card.exp_year,
              holder: {
                name: this.card.holder_name
              }
            }
          })
        }
        else {
          this.setCheckoutRequest({ creditCardInfo: {} })
        }
      },
      deep: true
    }
  },
  data() {
    return {
      pagarMeService: new PagarMeService(),
      cartService: new CartService(),
      checkoutService: new CheckoutService(),
      PaymentMethod: PaymentMethod,
      PaymentMethodProvider: PaymentMethodProvider,
      panelOpen: null,
      showCardForm: false,
      card: {
        number: "",
        holder_name: "",
        exp_month: "",
        exp_year: "",
        cvv: ""
      },
      monthsList: [],
      yearsList: [],
      order: {},
      cards: [],
      cardId: 'new',
      cardChoosen: null,
      cardChoosenCvv: null,
      saveCard: false
    };
  },
  methods: {
    ...mapActions([
      "createInstallments",
      "setCheckoutRequest",
      "getProfile",
      "setConsentTermIsChecked",
      "setInstallment",
      "clearCart",
      "setCartSuccess"
    ]),
    async getCustomerCards() {
      let userId = 0

      if (this.paymentMethodProviders.creditCard == this.PaymentMethodProvider.Asaas) {
        userId = this.profile.userId
      } else if (this.paymentMethodProviders.creditCard == this.PaymentMethodProvider.PagarMe) {
        userId = this.profile.user.pagarMeCustomerId
      }

      const response = await this.cartService.listCustomerCards(userId, this.paymentMethodProviders.creditCard)
      this.cards = response.data
    },
    getParcelamentNumber() {
      const parcelamentList = this.campaignLandingPageConfig.campaign.campaignPaymentMethodParcelamentList
      const paymentParcelament = parcelamentList.filter(item => item.paymentMethodId === this.PaymentMethod[this.paymentType])
      return paymentParcelament[0].parcelament;
    },
    redirect(data) {
      this.$emit("redirect", data);
    },
    mountYearList() {
      const startYear = new Date().getFullYear();
      const endYear = startYear + 15;
      for (let i = startYear; i <= endYear; i++) {
        this.yearsList.push(i);
      }
    },
    mountMonthList() {
      for (var i = 1; i <= 12; i++) {
        this.monthsList.push(String(i).padStart(2, '0'));
      }
    },
    nameToUpperCase() {
      this.card.holder_name = this.card.holder_name.toUpperCase()
    },
    async save() {
      if (!this.consentTermIsChecked) {
        return this.$store.commit(
          "SET_SNACKBAR_MESSAGE",
          "Para prosseguir é necessário confirmar o termo de anuência!")
      }

      if (this.isValid()) {
        await this.createOrder()
        await this.saveOrder()
      }
    },
    openConsentTerm(paymentMethod) {
        if (
            paymentMethod == PaymentMethod.CreditCard &&
            !this.isPaymentSimplified
        ) {
            if (!this.isCreditCardInformationValid()) return;
        }
        if (this.isValid()) {
            this.$refs.consertTermComponent.open();
        }
    },
    async tokenizerCard() {
      if (this.cardId === 'new' &&
      this.paymentMethodProviders.creditCard === this.PaymentMethodProvider.PagarMe) {
        const response = await this.pagarMeService.tokenizerCard({
          type: 'card',
          card: this.card
        })
        return response.data.id;
      } 
      return null
    },
    getCardId() {
      if (this.cardId !== 'new') return this.cardId
      else null
    },
    async createOrder() { 
      const cart = cartStorageService.getCart()

      this.order = {
          userId: this.profile.userId,
          cartId: (cart && cart.id) ? cart.id : 0,
          campaignId: this.campaignLandingPageConfig.campaignId,
          paymentMethodId: this.PaymentMethod.CreditCard,
          paymentRecurrencePeriodId: this.recurrency,
          parcelamentPayRollAccount: 0,
          installments: this.selectedInstallment.installment,
          totalValue: this.selectedInstallment.total_value,
          donationProductId: 0,
          campaignDonationProductAnswers: this.campaignDonationProductAnswers,
          coin: "BRL",
          creditCard: {
            cardId: this.getCardId(),
            cardToken: await this.tokenizerCard(),
            saveCard: this.saveCard,
            cardNumber: this.card.number,
            expirationMonth: this.card.exp_month,
            expirationYear: this.card.exp_year,
            holderName: this.card.holder_name,
            cvv: this.card.cvv
          },
          cartItems: this.createItemsObject(),
          expectedCarts: [],
          giftId: this.selectedGift ? this.selectedGift.id : null,
          giftValue: this.selectedGift ? this.selectedGift.initialValue: null
        }
        
        if (this.taxOwner === 2 && this.selectedInstallment.total_value != this.checkoutValue) {
          this.order.taxValue = (this.selectedInstallment.total_value - this.checkoutValue)
        }

        if (cart && cart.id) {
          this.order.creationDate = cart.creationDate
        }

        console.log('order', this.order)
      },
    async saveOrder() {
      this.order.checkoutRequest = this.checkoutRequest;

      this.checkoutService.postCheckout(this.order).then(async (response) => {
        if (this.saveCard === false &&
        this.cardId === 'new' &&
        this.paymentMethodProviders.creditCard === this.PaymentMethodProvider.PagarMe) {
          await this.deletePagarMeLastCard(response)
        }
        
        if (this.saveCard === false &&
        this.cardId === 'new' &&
        this.paymentMethodProviders.creditCard === this.PaymentMethodProvider.Asaas) {
          await this.deleteAsaasLastCard()
        }
        
        if (response) {
          this.setCartSuccess({
            cartId: response.cart_id,
            totalValue: this.order.totalValue,
            campaign: this.campaignLandingPageConfig.campaign,
            paymentMethodId: this.order.paymentMethodId,
          });
          
          this.clearCart()
          this.redirect({
            method: 'creditCard',
            data: response
          })
        }
      }).catch(() => {

      })
    },
    async deletePagarMeLastCard(response) {
      await this.getCustomerCards();
      const newCard = this.cards.find((c) => {
        return c.holder_name === response.charges[0].holder_name &&
        c.last_four_digits === response.charges[0].last_four_digits
      })
      await this.cartService.deleteCustomerCard(this.profile.user.pagarMeCustomerId, newCard.id)
    },
    async deleteAsaasLastCard() {
      const cardToken = this.order.creditCard.cardToken;
      await this.cartService.deleteByCardId(cardToken)
    },
    createItemsObject() {
      const items = [];
      for (let item of this.itemsToCheckout) {
        items.push({
          id: item.id,
          projectId: item.projectId,
          itemValue: parseFloat(item.itemValue),
          donationProductId: item.donationProductId,
          project: { id: item.project.id, projectTypeId: item.project.projectTypeId },
          donationProduct: item.donationProduct,
        })
      }
      return items
    },
    selectCard(cardId) {
      this.clearChoosenCvv();
      this.clearCheckoutRequest();
      this.setConsentTermIsChecked(false);
      this.cardId = cardId
      this.cardChoosen = this.cards.find((c) => c.id === cardId)
    },
    createCreditCardInfoForCardChoosen() {
      if (this.cardChoosenCvv) {
        const creditCardInfo = {
          creditCardInfo: {
            cardNumber: this.cardChoosen.last_four_digits,
            cvv: this.cardChoosenCvv,
            expirationMonth: this.cardChoosen.exp_month,
            expirationYear: this.cardChoosen.exp_year,
            holder: {
              name: this.cardChoosen.holder_name
            }
          }
        }
      this.setCheckoutRequest(creditCardInfo)
      } else {
        this.clearCheckoutRequest();
        this.setConsentTermIsChecked(false);
      }
    },
    exitCard() {
      this.clearCheckoutRequest();
      this.setConsentTermIsChecked(false);
      this.cardId = null
      this.card = {}
      this.saveCard = false;
    },
    clearChoosenCvv() {
      this.cardChoosenCvv = null
    },
    clearCheckoutRequest() {
      this.setCheckoutRequest({ creditCardInfo: {} })
    }
  }
}
</script>
